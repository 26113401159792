<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">ID</div>
      <div class="details">{{ contentForm.billing_burst_usage_id }}</div>
      <div class="titles">Service Provider</div>
      <div class="details">{{ contentForm.service_provider_name }}</div>
      <div class="titles">Usage Date</div>
      <div class="details">
        {{ contentForm.billing_year }} {{ contentForm.billing_month }}
      </div>

      <div style="margin-top: 20px;">
        <el-button
          @click="handleDelete(contentFormInfo)"
          class="deleteBtn"
          :disabled="!canWrite()"
          >Delete
        </el-button>
      </div>
    </div>

    <el-dialog
      :visible.sync="openModal"
      fullscreen
      :show-close="true"
      :before-close="handleClose"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting || isLoading"
    >
      <el-scrollbar :native="false" style="margin-top: 34px">
        <view-burst-usage
          v-if="formAction === 'view'"
          :burst-usage="contentForm"
        ></view-burst-usage>
        <div
          v-else
          style="max-height: calc(100vh - 34px);"
          id="billingBurstUsageForm"
        >
          <el-form
            class="generate-burst-usage-form"
            ref="billingBurstUsageForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex" style="padding-top: 34px">
              <el-col :span="6"></el-col>
              <el-col :span="18">
                <page-header title="Generate Burst Usage" />
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="6"></el-col>
              <el-col :span="9">
                <el-form-item label="Billing Month" prop="billing_date">
                  <el-date-picker
                    v-model="contentForm.billing_date"
                    type="month"
                    placeholder="Pick a month"
                    value-format="yyyy-MM"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="6"></el-col>
              <el-col :span="9">
                <el-form-item label="Service Provider" prop="sp_id">
                  <el-select
                    v-model="contentForm.sp_id"
                    placeholder="Select Service Provider"
                    style="width: 100%"
                    filterable
                    default-first-option
                    :clearable="true"
                  >
                    <el-option
                      v-for="serviceProvider in serviceProviders"
                      :key="serviceProvider.sp_id"
                      :label="serviceProvider.sp_name"
                      :value="serviceProvider.sp_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px"
          >
            <el-button
              type="primary"
              @click="submitForm"
              class="submitBtn"
              :disabled="!can('system.burst-usages.write')"
              >Generate
            </el-button>
            <el-button @click="handleCancel" class="cancelBtn"
              >Cancel
            </el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { mapState, mapActions } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PageHeader from "@/components/PageHeader";
import ViewBurstUsage from "./view";

export default {
  name: "BurstUsageGenerate",
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    ViewBurstUsage
  },
  data() {
    return {
      rules: {
        billing_date: [
          {
            required: true,
            trigger: "blur",
            message: "Billing month is required"
          }
        ],
        sp_id: [
          {
            required: true,
            trigger: "change",
            message: "Service Provider is required"
          }
        ]
      },
      isSubmitting: false,
      billingDate: "",
      spId: ""
    };
  },
  computed: {
    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders,
      isLoading: state => state.loading
    })
  },

  methods: {
    ...mapActions("burstUsages", {
      getBurstUsageItems: "getBurstUsageItems",
      generateBurstUsages: "generateBurstUsages",
      deleteContentMethod: "deleteBurstUsages"
    }),
    ...mapActions("serviceproviders", {
      getServiceProviders: "getServiceProviders"
    }),
    submitForm() {
      this.$refs.billingBurstUsageForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          this.generateBurstUsages(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.$message({
                type: "success",
                message: "Burst Usage generated Successfully"
              });

              EventBus.$emit("list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    handleClose() {
      this.openModal = false;
      this.formAction = "";
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (val === "add") {
          this.getServiceProviders();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/node_common.scss";
.titles {
  color: #6e7681;
  font-weight: bold;
  margin-top: 10px;
}

.details {
  color: #a0a8b5;
  font-size: 0.85em;
  margin-top: 5px;
}

.submitBtn {
  height: 44px;
  background: $content-theme-color;
  border: none;

  &:hover {
    background: $content-theme-hover-color;
  }
}

.cancelBtn {
  border: none;
  height: 44px;

  &:hover {
    background: lightgrey;
    color: white;
  }
}

.editBtn {
  margin-top: 10px;
  height: 44px;
  background: $content-theme-color;
  border: none;
  color: white;
  font-size: 0.8em;
  font-weight: bold;

  &:hover {
    color: white;
    background: $content-theme-hover-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
</style>
