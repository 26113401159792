<template>
  <el-row
    type="flex"
    v-loading="isLoading"
    :key="componentKey"
    class="burst-usage-details"
  >
    <el-col :span="4"></el-col>
    <el-col :span="16">
      <el-header class="burst-usage-title">{{ burstUsageTitle }}</el-header>
      <el-header class="flex-space-between">
        <div></div>
        <div>
          <div class="flex-space-between">
            <div class="secondary-label">Service Provider:</div>
            <div class="secondary-value" style="padding-left: 15px;">
              {{ this.burstUsageItems.service_provider_name }}
            </div>
          </div>
          <div class="flex-space-between">
            <div class="secondary-label">Billing Mode:</div>
            <div class="secondary-value" style="padding-left: 15px;">
              {{ this.burstUsageItems.billing_mode }}
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <div style="padding-bottom: 30px">
          <div class="ac-name-row-wrapper">
            <div
              style="display: flex; justify-content: space-between; align-item:center"
            >
              <div class="ac-name-row">
                IVA
              </div>
              <div
                style="display: flex; align-item:center; padding-right: 10px"
                v-if="
                  this.burstUsageItems.billing_burst_usage_items.iva.length > 0
                "
              >
                <el-button
                  ref="downloadIvaCsvButton"
                  @click="generatIvaOrDvaBurstUsageCsv('IVA')"
                  >Download CSV</el-button
                >
              </div>
            </div>
          </div>
          <el-table
            :data="this.burstUsageItems.billing_burst_usage_items.iva"
            class="burst-usage-table"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div style="padding-left: 0px">
                  <h3>
                    {{ "Daily Burst Usages" }}
                  </h3>
                  <div
                    v-if="
                      props.row.burst_dates.length === 1 &&
                        props.row.burst_dates[0].date === null
                    "
                  >
                    No Information Available
                  </div>
                  <div v-else>
                    <li
                      v-for="(item, index) in props.row.burst_dates"
                      :key="index"
                    >
                      {{ "" + getBurstDate(item.date) }} -
                      {{ item.feature_burst_usage + " Burst Usage(s)" }}
                    </li>
                    <div style="padding-bottom: 20px"></div>
                    <div>
                      {{ "Dates are displayed in " + timezone + " timezone" }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="this.burstUsageItems.billing_mode === 'sp'"
              prop="sp_plan_name"
              label="Service Provider Plan Name"
            >
            </el-table-column>

            <el-table-column
              v-if="this.burstUsageItems.billing_mode === 'ac'"
              label="Account"
            >
              <template slot-scope="props">
                <div style="display: block">
                  <div>{{ props.row.account_name }}</div>
                  <div>{{ "Business Plan: " + props.row.bp_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="purchased_unit"
              label="Purchased IVA Sessions"
            >
            </el-table-column>
            <el-table-column
              prop="purchased_burst_unit"
              label="Allowed Burst Sessions"
            >
            </el-table-column>
            <el-table-column prop="feature_usage" label="Max Active Session">
            </el-table-column>
            <el-table-column prop="feature_burst_usage" label="Burst Usages">
            </el-table-column>
          </el-table>
        </div>

        <div class="ac-name-row-wrapper">
          <div
            style="display: flex; justify-content: space-between; align-item:center"
          >
            <div class="ac-name-row">
              DVA
            </div>
            <div
              style="display: flex; align-item:center; padding-right: 10px"
              v-if="
                this.burstUsageItems.billing_burst_usage_items.dva.length > 0
              "
            >
              <el-button
                ref="downloadDvaCsvButton"
                @click="generatIvaOrDvaBurstUsageCsv('DVA')"
                >Download CSV</el-button
              >
            </div>
          </div>
        </div>

        <el-table
          :data="this.burstUsageItems.billing_burst_usage_items.dva"
          class="burst-usage-table"
        >
          <el-table-column prop="account_name" label="Account">
          </el-table-column>
          <!-- <el-table-column prop="feature_name" label="Feature">
            </el-table-column> -->
          <el-table-column label="Purchased">
            <el-table-column prop="purchased_unit" label="Purchased DVAs">
            </el-table-column>
            <el-table-column label="Burst Percentage">
              <template slot-scope="scope">
                <div>{{ scope.row.purchased_burst_unit }}%</div>
              </template>
            </el-table-column>
            <el-table-column label="Max Total DVAs">
              <template slot-scope="scope">
                <div>
                  {{
                    Math.ceil(
                      (scope.row.purchased_usages +
                        scope.row.purchased_burst_usages) /
                        20000
                    )
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="Usage">
            <el-table-column prop="feature_usage" label="Messages Used">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.feature_usage.toLocaleString() }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Total DVAs Used">
              <template slot-scope="scope">
                <div>
                  {{ Math.ceil(scope.row.feature_usage / 20000) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Burst DVAs Used">
              <template slot-scope="scope">
                <div slot="content">
                  {{ Math.ceil(scope.row.feature_burst_usage / 20000) }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-main>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FileDownloader from "@/mixins/FileDownloader";

export default {
  name: "BurstUsageView",
  mixins: [FileDownloader],
  props: {
    burstUsage: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      componentKey: 0
    };
  },
  mounted() {
    this.isLoading = true;
    this.getBurstUsageItems(this.burstUsage.billing_burst_usage_id).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    ...mapState("burstUsages", {
      burstUsageItems: state => state.burstUsageItems
    }),
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime",
      timezone: "timezone",
      displayDateFormat: "displayDateFormat"
    }),
    burstUsageTitle() {
      return (
        "Burst Usages of " +
        this.burstUsageItems.billing_month +
        " " +
        this.burstUsageItems.billing_year
      );
    }
  },
  methods: {
    ...mapActions("burstUsages", {
      getBurstUsageItems: "getBurstUsageItems",
      generateBurstUsageCsv: "generateBurstUsageCsv"
    }),

    generatIvaOrDvaBurstUsageCsv(plan_type) {
      this.isLoading = true;
      this.generateBurstUsageCsv({
        billing_burst_usage_id: this.burstUsageItems.billing_burst_usage_id,
        plan_type: plan_type
      })
        .then(response => {
          this.isLoading = false;
          this.$message({
            type: "success",
            message: plan_type + " Burst Usage CSV generated Successfully"
          });
          this.downloadCSVFile(
            response,
            plan_type +
              " Burst Usage for " +
              this.burstUsageItems.service_provider_name +
              " " +
              this.burstUsageItems.billing_month +
              " " +
              this.burstUsageItems.billing_year
          );
        })
        .catch(err => {
          this.isSubmitting = false;
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },

    getBurstDate(date) {
      return this.formattedDateTime(date, this.displayDateFormat.toUpperCase());
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;

.burst-usage-details {
  .burst-usage-table {
    border-bottom: 1px solid #a0a8b5;
    table.el-table__body {
      td.is-right > .cell {
        justify-content: right;
      }
    }
  }

  .ac-name-row-wrapper {
    padding: 10px 0;
    background-color: $content-theme-row-hover-color;
  }

  .ac-name-row {
    padding: 10px;
    font-size: 1.1em;
    font-weight: 700;
  }

  .ac-plan-name-row-wrapper {
    padding: 10px 0;
    color: $content-theme-color;
    > .ac-plan-name-row {
      padding: 10px;
      font-size: 1em;
      font-weight: 600;
    }
  }

  .flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  .burst-usage-title {
    text-align: center;
    font-size: 2em;
    font-weight: 600;
  }
  .primary-label {
    font-size: 1.2em;
  }

  .primary-value {
    font-size: 1.2em;
    color: $content-theme-color;
  }

  .secondary-label {
    font-size: 1em;
  }

  .secondary-value {
    font-size: 1em;
    color: $content-theme-color;
  }
}
</style>
