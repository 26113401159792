var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],key:_vm.componentKey,staticClass:"burst-usage-details",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":4}}),_c('el-col',{attrs:{"span":16}},[_c('el-header',{staticClass:"burst-usage-title"},[_vm._v(_vm._s(_vm.burstUsageTitle))]),_c('el-header',{staticClass:"flex-space-between"},[_c('div'),_c('div',[_c('div',{staticClass:"flex-space-between"},[_c('div',{staticClass:"secondary-label"},[_vm._v("Service Provider:")]),_c('div',{staticClass:"secondary-value",staticStyle:{"padding-left":"15px"}},[_vm._v(" "+_vm._s(this.burstUsageItems.service_provider_name)+" ")])]),_c('div',{staticClass:"flex-space-between"},[_c('div',{staticClass:"secondary-label"},[_vm._v("Billing Mode:")]),_c('div',{staticClass:"secondary-value",staticStyle:{"padding-left":"15px"}},[_vm._v(" "+_vm._s(this.burstUsageItems.billing_mode)+" ")])])])]),_c('el-main',[_c('div',{staticStyle:{"padding-bottom":"30px"}},[_c('div',{staticClass:"ac-name-row-wrapper"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-item":"center"}},[_c('div',{staticClass:"ac-name-row"},[_vm._v(" IVA ")]),(
                this.burstUsageItems.billing_burst_usage_items.iva.length > 0
              )?_c('div',{staticStyle:{"display":"flex","align-item":"center","padding-right":"10px"}},[_c('el-button',{ref:"downloadIvaCsvButton",on:{"click":function($event){return _vm.generatIvaOrDvaBurstUsageCsv('IVA')}}},[_vm._v("Download CSV")])],1):_vm._e()])]),_c('el-table',{staticClass:"burst-usage-table",attrs:{"data":this.burstUsageItems.billing_burst_usage_items.iva}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticStyle:{"padding-left":"0px"}},[_c('h3',[_vm._v(" "+_vm._s("Daily Burst Usages")+" ")]),(
                    props.row.burst_dates.length === 1 &&
                      props.row.burst_dates[0].date === null
                  )?_c('div',[_vm._v(" No Information Available ")]):_c('div',[_vm._l((props.row.burst_dates),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s("" + _vm.getBurstDate(item.date))+" - "+_vm._s(item.feature_burst_usage + " Burst Usage(s)")+" ")])}),_c('div',{staticStyle:{"padding-bottom":"20px"}}),_c('div',[_vm._v(" "+_vm._s("Dates are displayed in " + _vm.timezone + " timezone")+" ")])],2)])]}}])}),(this.burstUsageItems.billing_mode === 'sp')?_c('el-table-column',{attrs:{"prop":"sp_plan_name","label":"Service Provider Plan Name"}}):_vm._e(),(this.burstUsageItems.billing_mode === 'ac')?_c('el-table-column',{attrs:{"label":"Account"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticStyle:{"display":"block"}},[_c('div',[_vm._v(_vm._s(props.row.account_name))]),_c('div',[_vm._v(_vm._s("Business Plan: " + props.row.bp_name))])])]}}],null,false,952769403)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"purchased_unit","label":"Purchased IVA Sessions"}}),_c('el-table-column',{attrs:{"prop":"purchased_burst_unit","label":"Allowed Burst Sessions"}}),_c('el-table-column',{attrs:{"prop":"feature_usage","label":"Max Active Session"}}),_c('el-table-column',{attrs:{"prop":"feature_burst_usage","label":"Burst Usages"}})],1)],1),_c('div',{staticClass:"ac-name-row-wrapper"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-item":"center"}},[_c('div',{staticClass:"ac-name-row"},[_vm._v(" DVA ")]),(
              this.burstUsageItems.billing_burst_usage_items.dva.length > 0
            )?_c('div',{staticStyle:{"display":"flex","align-item":"center","padding-right":"10px"}},[_c('el-button',{ref:"downloadDvaCsvButton",on:{"click":function($event){return _vm.generatIvaOrDvaBurstUsageCsv('DVA')}}},[_vm._v("Download CSV")])],1):_vm._e()])]),_c('el-table',{staticClass:"burst-usage-table",attrs:{"data":this.burstUsageItems.billing_burst_usage_items.dva}},[_c('el-table-column',{attrs:{"prop":"account_name","label":"Account"}}),_c('el-table-column',{attrs:{"label":"Purchased"}},[_c('el-table-column',{attrs:{"prop":"purchased_unit","label":"Purchased DVAs"}}),_c('el-table-column',{attrs:{"label":"Burst Percentage"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(scope.row.purchased_burst_unit)+"%")])]}}])}),_c('el-table-column',{attrs:{"label":"Max Total DVAs"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(Math.ceil( (scope.row.purchased_usages + scope.row.purchased_burst_usages) / 20000 ))+" ")])]}}])})],1),_c('el-table-column',{attrs:{"label":"Usage"}},[_c('el-table-column',{attrs:{"prop":"feature_usage","label":"Messages Used"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.feature_usage.toLocaleString())+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Total DVAs Used"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(Math.ceil(scope.row.feature_usage / 20000))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Burst DVAs Used"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(Math.ceil(scope.row.feature_burst_usage / 20000))+" ")])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }