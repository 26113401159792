<template>
  <div style="width: 100%;">
    <page-header
      class="absolute"
      title="Burst Usages"
      style="padding: 20px 30px; width: calc(100% - 335px)"
    />
    <el-button
      class="createBtn"
      @click="createContentItem"
      :disabled="!can('system.burst-usages.write')"
      >Generate Burst Usage</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            class="list-table"
            v-loading="loading"
            :data="burstUsages"
            @current-change="handleCurrentChange"
            highlight-current-row
            v-show="can('system.burst-usages.read')"
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
          >
            <el-table-column label="ID">
              <template slot-scope="scope">
                <span>{{ scope.row.billing_burst_usage_id }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Service Provider">
              <template slot-scope="scope">
                <span>{{ scope.row.service_provider_name }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Billing Date">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.billing_year }}
                  {{ scope.row.billing_month }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "BurstUsageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["billing_burst_usage_id"]
    };
  },

  computed: {
    ...mapState("burstUsages", {
      burstUsages: state => state.burstUsages,
      loading: state => state.loading
    })
  },

  methods: {
    ...mapActions("burstUsages", {
      contentAPI: "getBurstUsages"
    }),

    handleCurrentChange(row) {
      const index = this.burstUsages.findIndex(
        burstUsage => burstUsage.sp_id === row.sp_id
      );
      this.handleSelect(index, row);
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    async handleDoubleClick(row, column, event) {
      this.handleCurrentChange(row);
      event.stopPropagation();
      this.handleView(null, row);
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
