<script>
import { mapState } from "vuex";
import List from "./pages/list";
import CreateOrEdit from "./pages/generate";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "BurstUsageIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("burstUsages", {
      burstUsages: state => state.burstUsages
    })
  },

  methods: {
    initContent() {
      return {
        billing_date: "",
        sp_id: ""
      };
    }
  },

  mounted() {
    this.contentType = "BillingBurstUsage";
    this.primaryKey = "billing_burst_usage_id";
    this.scope = "sys";
  }
};
</script>

<style lang="scss" scoped></style>
